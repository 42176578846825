import { useEffect, useState } from 'react';
import { FaBold, FaCode, FaItalic, FaUnderline } from 'react-icons/fa';
import {
  MdFormatListNumbered,
  MdList,
  MdLooksOne,
  MdLooksTwo,
} from 'react-icons/md';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import { Box, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import {
  isBlockActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
} from './SlateEditorUtil';

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <ToggleButton
      sx={{ minWidth: 48, width: 48, height: 48, border: 'none' }}
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <ToggleButton
      sx={{ width: 48, height: 48, border: 'none' }}
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

export const SlateToolbar = () => {
  const theme = useTheme();
  return (
    <Box sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      <ToggleButtonGroup
        size="small"
        arial-label="text formatting"
        sx={{ '*': { border: 'none' }, flexWrap: 'wrap' }}
      >
        {MarkButton({ format: 'bold', icon: <FaBold size={18} /> })}
        {MarkButton({
          format: 'italic',
          icon: <FaItalic size={18} />,
        })}
        {MarkButton({
          format: 'underline',
          icon: <FaUnderline size={18} />,
        })}
        {MarkButton({
          format: 'code',
          icon: <FaCode size={20} />,
        })}
        {/* </ToggleButtonGroup>
      <Divider orientation="vertical" />
      <ToggleButtonGroup size="small" arial-label="text formatting" exclusive> */}
        {BlockButton({ format: 'heading-one', icon: <MdLooksOne size={24} /> })}
        {BlockButton({ format: 'heading-two', icon: <MdLooksTwo size={24} /> })}
        {BlockButton({
          format: 'numbered-list',
          icon: <MdFormatListNumbered size={24} />,
        })}
        {BlockButton({
          format: 'bulleted-list',
          icon: <MdList size={24} />,
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

export const HoveringToolbar = () => {
  const editor = useSlate();
  const [showToolbar, setShowToolbar] = useState(false);
  const { selection } = editor;
  useEffect(() => {
    if (!selection || Editor.string(editor, selection) === '') {
      setShowToolbar(false);
    } else {
      setShowToolbar(true);
    }
  }, [editor, selection]);

  return (
    <div hidden={!showToolbar}>
      <SlateToolbar />
    </div>
  );
};
