import { useTheme, SxProps, Theme } from '@mui/material';

import { PageContent } from 'components/Page';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import React from 'react';
import { MdAdd, MdAutoGraph } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface IGoalsEmptyPageContentProps {
  createPath?: string;
  sx?: SxProps<Theme>;
}

export const GoalsEmptyPageContent: React.FC<IGoalsEmptyPageContentProps> = ({
  createPath = '/goals/create',
  sx = {},
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  return (
    <PageContent
      title={baseLanguage?.relationships?.goals?.empty_state?.title}
      subtitle={baseLanguage?.relationships?.goals?.empty_state?.description}
      icon={MdAutoGraph}
      iconColor={theme.palette.success.main}
      containerStylings={sx}
      actions={[
        {
          label: baseLanguage?.globals?.goals?.create_goal_button_label,
          action: () => {
            navigate(createPath);
          },
          icon: MdAdd,
          color: 'info',
          'data-cy': 'relationship-goals-empty-create-goal-button',
        },
      ]}
    />
  );
};
