import { FormControl, Paper, useTheme, SxProps, Theme } from '@mui/material';

// Import React dependencies.
import React, { useCallback, useMemo, useState } from 'react';
// Import the Slate editor factory.
import { BaseEditor, Descendant, createEditor } from 'slate';

// Import the Slate components and React plugin.
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';

import {
  HOTKEYS,
  SlateElement,
  SlateLeaf,
  toggleMark,
} from './SlateEditorUtil';
import { SlateToolbar } from './SlateToolbar';

import isHotkey from 'is-hotkey';
import { Control, Controller } from 'react-hook-form';

export const emptyInitialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export type ParagraphElement = {
  type: 'paragraph';
  children: CustomText[];
};

type CustomElement = ParagraphElement;
type CustomText = { text: string };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface IFormRichTextProps {
  name: string;
  control: Control;
  initialValue?: Descendant[];
  required?: boolean;
  sx?: SxProps<Theme>;
  'data-cy'?: string;
}

export const FormRichText: React.FC<IFormRichTextProps> = ({
  name,
  control,
  initialValue = emptyInitialValue,
  required,
  sx = {},
  'data-cy': dataCy,
}) => {
  const theme = useTheme();

  const [formHeight, setFormHeight] = useState<Number>();

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback(
    (props: any) => <SlateElement {...props} />,
    [],
  );
  const renderLeaf = useCallback((props: any) => <SlateLeaf {...props} />, []);

  return (
    <FormControl
      fullWidth
      sx={{
        '& > div': {
          height: '100%',
          color: theme.palette.text.primary,
          fontWeight: 500,
          display: 'flex',
          lineHeight: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
        '& div[role="textbox"]': {
          height: '100%',
          maxHeight: `${formHeight}px`,
          overflowY: 'auto',
          px: 1.5,
          '&:focus': {
            outline: 'none',
          },
        },
        ...sx,
      }}
    >
      <Paper variant="outlined">
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          defaultValue={[]}
          render={({ field: { onChange } }) => (
            <Slate
              editor={editor}
              initialValue={initialValue}
              onChange={(value: any) => {
                setFormHeight(document.getElementById('content')?.clientHeight);
                onChange(value);
              }}
            >
              <SlateToolbar />
              <Editable
                id={name}
                renderLeaf={renderLeaf}
                renderElement={renderElement}
                onKeyDown={(event: any) => {
                  for (const hotkey in HOTKEYS) {
                    if (isHotkey(hotkey, event)) {
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                }}
                data-cy={dataCy}
              />
            </Slate>
          )}
        />
      </Paper>
    </FormControl>
  );
};
