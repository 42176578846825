import { FormControl, TextField } from '@mui/material';
import React from 'react';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';

interface IFormTextField extends FieldValues {
  name: string;
  control: Control;
  label: string | undefined;
  errors: FieldErrors<FieldValues>;
  required?: boolean | { value: boolean; message: string };
  rows?: number;
  defaultValue?: string;
}

/**
 *  @deprecated Use FormTextFieldTyped instead
 */
export const FormTextField: React.FC<IFormTextField> = ({
  name,
  control,
  label,
  errors,
  required = false,
  rows = 1,
  defaultValue,
  ...textFieldProps
}) => {
  return (
    <FormControl fullWidth sx={{ mt: 2.5 }}>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <TextField
            sx={{ label: { color: 'text.secondary', fontWeight: 400 } }}
            onChange={onChange}
            value={value}
            label={label}
            fullWidth
            color="info"
            error={!!errors[name]}
            data-cy={`components_FormTextField_${name}`}
            helperText={
              !!errors[name] &&
              (errors[name]?.message
                ? (errors[name]?.message as string)
                : 'This field is required')
            }
            multiline={rows > 1}
            rows={rows}
            {...textFieldProps}
            disabled={textFieldProps?.disabled}
          />
        )}
      />
    </FormControl>
  );
};
