import {
  getFullDateFormat,
  getLanguageFromCMS,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  useOrganization,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IGoal, IProfile, IRelationship } from '@guider-global/shared-types';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { MDIcons, SanityIcon } from 'components';
import { format, parseISO } from 'date-fns';
import { useMobileMediaQuery, useProfiles } from 'hooks';
import React from 'react';

interface IGoalsDetails {
  goal: Partial<IGoal>;
  traineeProfile?: Partial<IProfile>;
  relationship: IRelationship;
}

export const GoalsDetails: React.FC<IGoalsDetails> = ({
  goal,
  traineeProfile,
  relationship,
}) => {
  const isMobile = useMobileMediaQuery();

  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const { getProfiles } = useProfiles({ getSilently: false });
  let [profile] = getProfiles() as Partial<IProfile>[];

  if (traineeProfile) {
    profile = traineeProfile;
  }
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });
  const { getProgram } = useSanityOrganizationPrograms({});
  const program = getProgram(relationship.programSlug);

  const customGoalCategories =
    program?.registration?.goals?.custom_goal_categories?.map(
      (customCategory) => ({
        label: customCategory.goal_category_name,
        value: customCategory.goal_category_slug?.current,
        icon: customCategory.goal_category_icon?.name ?? '',
      }),
    ) ?? [];

  const organizationGoalCategories =
    organization?.goal_categories?.categories?.map((goalCategory) => ({
      label: goalCategory.goal_category_name,
      value: goalCategory.goal_category_slug.current,
      icon: goalCategory.goal_category_icon?.name ?? '',
    })) ?? [];

  const goalCategories =
    organizationGoalCategories.concat(customGoalCategories);

  const locale = getLanguageFromCMS(baseLanguage?.language_code);

  const formattedDate = getFullDateFormat(baseLanguage.date_format);

  if (!goal?.createdAt) {
    return <></>;
  }

  const goalCategory = goalCategories.find(
    (goalCategory) => goalCategory.value === goal.categorySlug,
  )?.label;

  const icon = goalCategories.find(
    (goalCategory) => goalCategory.value === goal.categorySlug,
  )?.icon;

  const goalDate = format(parseISO(goal.createdAt.toString()), formattedDate, {
    locale,
  });

  return (
    <>
      <SanityIcon name={(icon as MDIcons) ?? ''} />
      <Typography variant="caption">{goalCategory}</Typography>
      <Typography variant="h5">{goal.objective}</Typography>
      {isMobile && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CalendarToday
              sx={{ mr: 1, color: 'text.disabled', fontSize: 20 }}
            />
            <Typography variant="caption" color="text.secondary">
              {goalDate}
            </Typography>
          </Box>
        </Box>
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={profile?.picture}
            sx={{
              width: '40px',
              height: '40px',
              mr: 1,
            }}
            variant="rounded"
          />
          <Box>
            <Typography
              variant="caption"
              component="p"
              color="text.secondary"
              fontWeight={400}
            >
              {baseLanguage?.relationships?.goals?.view_goal?.set_by_label}
            </Typography>
            <Typography variant="body2">{profile?.displayName}</Typography>
          </Box>
        </Box>
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CalendarToday
              sx={{ mr: 1, color: 'text.disabled', fontSize: 20 }}
            />
            <Typography variant="caption" color="text.secondary">
              {goalDate}
            </Typography>
          </Box>
        )}
      </Box>
      {goal.outcomes !== '' && goal.outcomes !== undefined && (
        <>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <Typography
            variant="h6"
            component="p"
            sx={{ fontSize: '1.125rem !important' }}
          >
            {baseLanguage?.relationships?.goals?.view_goal?.outcomes_label}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight={400}
          >
            {goal.outcomes}
          </Typography>
        </>
      )}
      {goal.rationale !== '' && goal.rationale !== undefined && (
        <>
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Typography
            variant="h6"
            component="p"
            sx={{ fontSize: '1.125rem !important' }}
          >
            {baseLanguage?.relationships?.goals?.view_goal?.benefits_label}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight={400}
          >
            {goal.rationale}
          </Typography>
        </>
      )}
    </>
  );
};
